@font-face {
  font-family: 'Montserrat';
  src: url('/../assets/font/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/../assets/font/Montserrat/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/../assets/font/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/../assets/font/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/../assets/font/Montserrat/Montserrat-ExtraBold.ttf') format('truetype');
  font-weight: 800  ;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/../assets/font/Montserrat/Montserrat-Italic.ttf') format('truetype');
  font-style: italic;
}
