.mat-ink-bar[class] {
  height: 4px;
}

.mat-form-field {
  .mat-form-field-flex {
    background-color: #FBFBFB;
  }
}


.mat-mdc-form-field {
  font-family: var(--font) !important;
}

.mat-form-field-prefix {
  color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-form-field-icon-prefix {
  color: #0009 !important;
}

.mat-icon.mat-warn { // NOTE: QUICK-FIX: Later we will change all mat icon color to danger
  color: var(--color-danger);
}

// override the default tooltip class
.mat-mdc-tooltip {
  @apply text-xs text-white ;
  background-color: #2D2D2D;
  border-radius: 2px;
  padding: 6px 14px !important;
  max-width: unset !important;
}

.mat-mdc-header-row {
  font-family: 'Montserrat' !important;
  color: #0000008a !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  white-space: nowrap !important;
}

.mat-table .mat-cell, .mat-table .mat-header-cell {
  box-sizing: content-box !important;
  padding-left: var(--padding-12) !important;
  padding-right: var(--padding-12) !important;
  white-space: nowrap !important;
}

.mat-mdc-row, .mdc-data-table__content {
  font-family: 'Montserrat' !important;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 150, 136, 0.02);
  backdrop-filter: blur(4px);
}

.mat-form-field-flex {
  background-color: #FBFBFB;
}

.mat-mdc-radio-button {
  display: flex;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: #FBFBFB !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.mat-mdc-form-field:focus-within .mat-mdc-form-field-icon-prefix {
  color: var(--mat-form-field-leading-icon-focus-color, #009688); /* Primary color on focus */
}

.mat-mdc-tab .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}

.mdc-dialog__title {
  display: flex !important;
}

.mat-mdc-tab-link.mdc-tab {
  min-width: 160px !important;
}

.mat-mdc-tab-link .mdc-tab-indicator__content--underline {
  border-top-width: 4px !important;
}

.mat-mdc-tab.mdc-tab {
  min-width: 160px !important;
}

.mat-mdc-icon-button.mat-mdc-button-base {
  //@apply text-primary; // NOTE: commented as detail view back button is showing in primary color
}

.mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  background-color: unset !important;
}

.mat-mdc-menu-content {
  padding: 0.5rem 0 !important;
}

.sidenav-content {
  overflow-y: overlay !important;
}

.custom-snackbar {
  background: unset !important;
  box-shadow: none !important;
  color: unset !important;
  padding: 0 !important;
}

.lib-celebration {
  .mat-dialog-container {
    padding: 0;
  }
}

/* Override the css for material bottom sheet */

.full-width-bottom-sheet {
  border-radius: 0 !important;
  height: 50%;
  display: flex;
  flex-direction: column;

  &.collapsed {
    min-width: calc(100% - var(--sidenav-collapsed-width)) !important;
    max-width: calc(100% - var(--sidenav-collapsed-width)) !important;
    margin: 0 0 0 var(--sidenav-collapsed-width) !important;
  }

  &.expanded {
    min-width: calc(100% - var(--sidenav-width)) !important;
    max-width: calc(100% - var(--sidenav-width)) !important;
    margin: 0 0 0 var(--sidenav-width) !important;
  }

  .mat-bottom-sheet-container-xlarge {
    max-width: 100% !important;
    margin: 12px !important;
    border-radius: 8px !important;
  }
}

.full-width-bottom-sheet-document-navigation {
  border-radius: 0 !important;
  height: 50%;
  display: flex;
  flex-direction: column;
  min-width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 24px !important;

  .mat-bottom-sheet-container-xlarge {
    min-width: 100% !important;
    max-width: 100% !important;
    border-radius: 8px !important;
    padding: 0 !important;
  }
}

// overriding classes from firebaseUi.css for Social Login buttons

.firebaseui-container {
  width: 100% !important;
  margin: 0 !important;
  max-width: none !important;
}

.firebaseui-card-content, .firebaseui-card-footer {
  padding: 0 !important;
}

.firebaseui-idp-button {
  display: flex !important;
  flex-direction: row;
  justify-content: center;
  padding: 12px 52px;
  align-items: center;
  align-self: stretch;
  gap: 10px;
  min-width: 155px !important;
  max-width: 100% !important;
  background: white !important;
  border-radius: 50px !important;
  border: 1px solid #E0E0E9 !important;
  width: 100% !important;
}

.firebaseui-idp-text.firebaseui-idp-text-short {
  display: none;
}

.firebaseui-idp-button.firebaseui-idp-google {
  margin-top: 18px !important;
}

.firebaseui-idp-button .firebaseui-idp-text {
  color: #1D1C2B !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: var(--font) !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
}

.firebaseui-idp-button[data-provider-id="microsoft.com"] {
  margin-bottom: 18px !important;
}

.firebaseui .mdl-spinner--single-color .mdl-spinner__layer {
  border-color: var(--color-primary) !important;
}

.firebaseui .mdl-spinner--single-color .mdl-spinner__layer::after,
.firebaseui .mdl-spinner--single-color .mdl-spinner__layer::before {
  border-color: var(--color-primary) !important;
}

.firebaseui .firebaseui-busy-indicator {
  border-color: var(--color-primary) !important;
}

.firebaseui .firebaseui-busy-indicator::after,
.firebaseui .firebaseui-busy-indicator::before {
  border-color: var(--color-primary) !important;
}

.firebaseui-idp-icon {
  width: 21px;
  height: 21px;
}

ngx-datatable {
  width: 100%;
}

/* Style the container of the checkbox */
ngx-datatable .datatable-body-cell input[type="checkbox"],
ngx-datatable .datatable-header-cell input[type="checkbox"] {
  appearance: none; /* Remove the default checkbox style */
  -webkit-appearance: none; /* For Safari */
  width: 18px; /* Match Material checkbox size */
  height: 18px; /* Match Material checkbox size */
  border: 2px solid rgba(0, 0, 0, 0.54); /* Material border color */
  border-radius: 2px; /* Rounded corners */
  outline: none; /* Remove focus outline */
  cursor: pointer;
  display: inline-block;
  vertical-align: middle;
  background-color: #fff; /* Checkbox background */
  transition: all 0.2s ease-in-out;
}

/* Checkbox hover effect */
ngx-datatable .datatable-body-cell input[type="checkbox"]:hover,
ngx-datatable .datatable-header-cell input[type="checkbox"]:hover {
  border-color: rgba(0, 0, 0, 0.87); /* Darker border on hover */
}

/* Checkbox checked state */
ngx-datatable .datatable-body-cell input[type="checkbox"]:checked,
ngx-datatable .datatable-header-cell input[type="checkbox"]:checked {
  @apply bg-primary;
  //background-color:  /* Material primary color */
  border-color: #009688; /* Match background color */
}

ngx-datatable .datatable-body-cell input[type="checkbox"]:checked::after,
ngx-datatable .datatable-header-cell input[type="checkbox"]:checked::after {
  content: ''; /* Checkmark */
  display: block;
  width: 6px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin: 2px auto; /* Center the checkmark */
}

/* Indeterminate state (optional) */
ngx-datatable .datatable-body-cell input[type="checkbox"]:indeterminate,
ngx-datatable .datatable-header-cell input[type="checkbox"]:indeterminate {
  background-color: rgba(0, 0, 0, 0.54); /* Material indeterminate background */
  border-color: rgba(0, 0, 0, 0.54); /* Match border to background */
}

ngx-datatable .datatable-body-cell input[type="checkbox"]:indeterminate::after,
ngx-datatable .datatable-header-cell input[type="checkbox"]:indeterminate::after {
  content: ''; /* Horizontal bar for indeterminate state */
  display: block;
  width: 16px;
  height: 16px;
  background-color: white;
  margin: 8px auto; /* Center the bar */
}

ngx-datatable .datatable-body-cell,
ngx-datatable .datatable-header-cell {
  text-align: left; /* Center horizontally */
  vertical-align: middle; /* Center vertically */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Optional: Add padding to avoid overcrowding */
ngx-datatable .datatable-body-cell {
  padding: 8px; /* Adjust as needed */
}

.ngx-datatable .datatable-footer {
  overflow: hidden !important;
}
