:root {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    @apply rounded-lg;
    display: block;
    padding: 24px;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: auto;
    outline: 0;
    width: 100%;
    height: 100%;
    min-height: inherit;
    max-height: inherit;
  }

  .mat-mdc-dialog-content {
    display: block;
    margin: 0 -24px;
    padding: 0 24px;
    max-height: 65vh;
    overflow: auto;
  }

  .mat-mdc-dialog-title {
    margin: 0 0 20px !important;
    padding: 0px !important;
    display: block;
  }

  .mdc-dialog__title::before {
    margin: 0 0 20px;
    content: none;
    height: auto;
  }

  .mat-mdc-dialog-actions {
    padding: 8px 0;
    display: flex;
    flex-wrap: wrap;
    min-height: 52px;
    align-items: center;
    box-sizing: content-box;
    margin-bottom: -24px;
  }
}
