:root {
  .dark .mat-mdc-select-panel {
    // TODO : UNCOMMENT IT LATER
    //@apply border-gray-600;
  }

  .mat-mdc-select-panel {
    // TODO : UNCOMMENT IT LATER
    //@apply rounded shadow-lg border border-gray-200 p-2;
  }

  .cdk-overlay-pane:not(.mat-mdc-select-panel-above) div.mat-mdc-select-panel {
    @apply mt-1 rounded-t;
  }

  .mat-mdc-option {
    // TODO : UNCOMMENT IT LATER
    //@apply my-1 rounded text-sm font-medium h-10 transition duration-100 ease-out min-h-10;

    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      @apply mb-0;
    }

    &:hover,
    &.mat-active {
      // TODO : UNCOMMENT IT LATER
      //@apply bg-primary-600/10;

      .mat-icon {
        // TODO : UNCOMMENT IT LATER
        //@apply text-primary-600;
      }
    }

    .mat-icon {
      // TODO : UNCOMMENT IT LATER
      //@apply icon-sm transition duration-100 ease-out;
    }

    &.mdc-list-item--selected:not(.mdc-list-item--disabled):not(
        .mat-mdc-option-multiple
      ) {
      // TODO : UNCOMMENT IT LATER
      //@apply bg-primary-600/10;
    }
  }

  .mat-mdc-menu-item-submenu-trigger {
    @apply pr-12;
  }
}
