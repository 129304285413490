@use '@angular/material' as mat;
@import "../../var";
// @formatter:off
body {
  // Generic
  --padding-gutter: 1rem;
  --padding: 1.5rem;
  --padding-16: 1rem;
  --padding-12: 0.75rem;
  --padding-8: 0.5rem;
  --padding-4: 0.25rem;

  --color-accent: 255, 64, 129;
  --color-accent-contrast: 255, 255, 255;

  --color-danger: #FF6565;
  --color-danger-light: #FFF0F0;
  --color-danger-contrast: 255, 255, 255;

  --color-warn: #E17414;
  --color-warn-light: #FCF1E7;
  --color-warn-contrast: rgb(255, 255, 255);

  --color-success: #0EC372;
  --color-success-light: #EDFBF4;
  --color-success-contrast: rgb(255, 255, 255);

  --color-info: #2F80ED;
  --color-info-light: #EAF2FD;
  --color-info-contrast: rgb(255, 255, 255);

  --color-brand-primary: rgb(182, 205, 63);
  --color-brand-primary-contrast: rgb(255, 255, 255);

  --color-brand-secondary: rgb(20, 62, 37);
  --color-brand-secondary-contrast: rgb(255, 255, 255);

  --color-default: rgb(136, 136, 136);
  --color-default-contrast: rgb(255, 255, 255);

  --color-white-light: #FBFBFB;

  --color-black-secondary: #616161;
  --color-black-tertiary: #8D8D8D;

  --color-charcoal-gray: #242424;
  --color-pearl-gray: #F6F6F6;
  --color-grey-madium: #EDEEF1;
  --color-light-gray: #D0D0D0;
  --color-pale-aqua: #F1FAF9;

  @screen lg {
    --padding-gutter: 1.5rem;
  }

  // Typography
  --font: 'Montserrat', sans-serif;
  --font-weight-medium: 500;
  --font-caption: #{mat.font-weight($typography-config, caption) #{mat.font-size($typography-config, caption)}/#{mat.line-height($typography-config, caption)} mat.font-family($typography-config, caption)};
  --font-body-1: #{mat.font-weight($typography-config, body-1) #{mat.font-size($typography-config, body-1)}/#{mat.line-height($typography-config, body-1)} mat.font-family($typography-config, body-1)};
  --font-body-2: #{mat.font-weight($typography-config, body-2) #{mat.font-size($typography-config, body-2)}/#{mat.line-height($typography-config, body-2)} mat.font-family($typography-config, body-2)};
  --font-subheading-1: #{mat.font-weight($typography-config, subtitle-2) #{mat.font-size($typography-config, subtitle-2)}/#{mat.line-height($typography-config, subtitle-2)} mat.font-family($typography-config, subtitle-2)};
  --font-subheading-2: #{mat.font-weight($typography-config, subtitle-1) #{mat.font-size($typography-config, subtitle-1)}/#{mat.line-height($typography-config, subtitle-1)} mat.font-family($typography-config, subtitle-1)};
  --font-headline: #{mat.font-weight($typography-config, headline-5) #{mat.font-size($typography-config, headline-5)}/#{mat.line-height($typography-config, headline-5)} mat.font-family($typography-config, headline-5)};
  --font-title: #{mat.font-weight($typography-config, headline-6) #{mat.font-size($typography-config, headline-6)}/#{mat.line-height($typography-config, headline-6)} mat.font-family($typography-config, headline-6)};
  --font-display-1: #{mat.font-weight($typography-config, headline-4) #{mat.font-size($typography-config, headline-4)}/#{mat.line-height($typography-config, headline-4)} mat.font-family($typography-config, headline-4)};
  --font-display-2: #{mat.font-weight($typography-config, headline-3) #{mat.font-size($typography-config, headline-3)}/#{mat.line-height($typography-config, headline-3)} mat.font-family($typography-config, headline-3)};
  --font-display-3: #{mat.font-weight($typography-config, headline-2) #{mat.font-size($typography-config, headline-2)}/#{mat.line-height($typography-config, headline-2)} mat.font-family($typography-config, headline-2)};
  --font-display-4: #{mat.font-weight($typography-config, headline-1) #{mat.font-size($typography-config, headline-1)}/#{mat.line-height($typography-config, headline-1)} mat.font-family($typography-config, headline-1)};

  // Text Color
  --text-color: #{$dark-primary-text};
  --text-color-light: #{$light-primary-text};
  --text-secondary: #{$dark-secondary-text};
  --text-secondary-light: #{$light-secondary-text};
  --text-hint: #{$dark-disabled-text};
  --text-hint-light: #{$light-disabled-text};

  // Foreground
  --foreground-divider: #{map-get(map-get($vex-theme, foreground), divider)};

  // Background
  --background-base: rgb(245, 245, 248);
  --background-card: #{map-get(map-get($vex-theme, background), card)};
  --background-app-bar: #{map-get(map-get($vex-theme, background), app-bar)};
  --background-hover: #{map-get(map-get($vex-theme, background), hover)};

  // Sidenav
  --sidenav-width: 280px;
  --sidenav-collapsed-width: 72px;
  --sidenav-background: #{$sidenav-background};
  --sidenav-color: white;

  // Sidenav Item
  --sidenav-item-padding: var(--padding);
  --sidenav-toolbar-background: #{darken($sidenav-background, 1.5)};
  --sidenav-item-background-active: #{darken($sidenav-background, 3)};
  --sidenav-item-color: #A1A2B6;
  --sidenav-item-color-active: #{$light-primary-text};
  --sidenav-item-icon-color: #494B74;
  --sidenav-item-icon-color-active: rgb(var(--color-primary));
  --sidenav-item-border-color-active: rgb(var(--color-primary));
  --sidenav-item-dropdown-background: #{darken($sidenav-background, 3)};
  --sidenav-item-dropdown-background-hover: #{darken($sidenav-background, 4)};
  --sidenav-item-dropdown-gap: 12px;
  --sidenav-item-icon-gap: 16px;
  --sidenav-item-icon-size: 24px;
  --sidenav-item-border-color: transparent;
  --vex-sidenav-item-ripple-color: theme("colors.white / 10%");
  //--vex-sidenav-subheading-color: theme("colors.primary.300 / 80%");

  // Toolbar
  --toolbar-height: 64px;
  --toolbar-background: white;
  --toolbar-color: #{$dark-primary-text};
  --toolbar-icon-color: rgb(var(--color-primary));

  // Secondary Toolbar
  --secondary-toolbar-background: var(--background-card);
  --secondary-toolbar-height: 54px;
  --vex-secondary-toolbar-background: var(--vex-toolbar-background);

  // Navigation
  --navigation-height: 64px;
  --navigation-background: var(--background-card);
  --navigation-color: var(--text-secondary);

  // Footer
  --footer-height: 56px;
  --footer-z-index: 100;
  --footer-background: var(--background-card);
  --footer-color: var(--text-color);
  --footer-elevation: 0 -10px 30px 0 rgba(82, 63, 104, .06);

  // Page Layouts
  --page-layout-header-height: 200px;
  --page-layout-toolbar-height: 64px;

  // Misc
  --blink-scrollbar-width: 12px;
  --default-icon-size: 24px;
  --border-radius: 4px;
}
// @formatter:on
