.bg-gray-extra-light {
  background: #FBFBFB;
}

.text-dark-gray {
  color: rgba(0, 0, 0, 0.6);
}

.bg-blue-light {
  background: rgba(0, 163, 255, 0.1);
}

.text-blue {
  color: #00A3FF;
}

.bg-purple-light {
  background: rgba(104, 101, 255, 0.1);
}

.text-purple {
  color: #6865FF;
}
